






import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { Viewer } from "@toast-ui/vue-editor";

interface SiteNoticeResponse {
  parse: SiteNoticeitem;
}

interface SiteNoticeitem {
  title: string;
  pageid: number;
  text: string;
}

@Component({
  components: {
    Viewer,
  },
})
export default class SiteNotice extends Vue {
  $refs!: {
    viewer: Viewer;
  };
  done = false;
  item: SiteNoticeitem = {
    title: "",
    pageid: 0,
    text: "",
  };

  async fetchSiteNotice(): Promise<void> {
    try {
      const { data } = await axios.get<SiteNoticeResponse>(
        "https://librewiki.net/api.php",
        {
          params: {
            action: "parse",
            origin: window.origin,
            page: "mediawiki:sitenotice",
            prop: "text",
            formatversion: "2",
            format: "json",
          },
        }
      );
      this.item = data.parse;
      this.done = true;
      await this.$nextTick();
      for (const link of this.$refs.viewer.$el.querySelectorAll("a")) {
        const href = link.attributes.getNamedItem("href");
        if (href?.value.startsWith("/")) {
          href.value = `https://librewiki.net${href.value}`;
        }
      }
      return;
    } catch (err) {
      console.log(err);
      this.done = false;
    }
  }

  mounted(): void {
    this.fetchSiteNotice();
  }
}
