










import { Vue } from "vue-property-decorator";

export default class FooterContent extends Vue {}
