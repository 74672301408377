


















import { Component, Vue } from "vue-property-decorator";
import store from "@/store";

@Component
export default class Tools extends Vue {
  isactive = false;
  get canWrite(): boolean {
    return store.state.canWrite;
  }

  openWriteModal(): void {
    store.commit("setEditorOpen", true);
  }

  mounted(): void {
    document.addEventListener("scroll", this.scrollButton);
  }

  scrollButton(): void {
    let nowscroll = window.scrollY;
    if (nowscroll > 300 && store.state.canWrite == true) {
      this.isactive = true;
    } else {
      this.isactive = false;
    }
  }
  scrollDown(): void {
    window.scroll({
      left: 0,
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }

  scrollUp(): void {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  }
}
