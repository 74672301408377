
































































































import { Component, Vue } from "vue-property-decorator";
import Gravatar from "vue-gravatar";
import { startLoginProcess, logout } from "@/auth";
import store from "@/store";
import axios from "axios";

interface AutoCompleteResponse {
  batchcomplete: string;
  continue: {
    sroffset: number;
    continue: string;
  };
  query: {
    searchinfo: {
      totalhits: number;
    };
    search: AutoCompleteResponseItem[];
  };
}

interface AutoCompleteResponseItem {
  ns: number;
  title: string;
  pageid: number;
  size: number;
  wordcount: number;
  snippet: string;
  timestamp: string;
}

@Component({
  components: {
    Gravatar,
  },
})
export default class NavBar extends Vue {
  searchInput = "";
  settings = {
    data: {
      wikiname: "리브레 위키",
    },
  };
  autoCompleteItem = [""];

  get user(): typeof store.state.user {
    return store.state.user;
  }

  async login(): Promise<void> {
    startLoginProcess(this.$route.fullPath);
  }

  async logout(): Promise<void> {
    await logout();
    location.reload();
  }

  go(): void {
    if (this.searchInput) {
      location.href = `https://librewiki.net/index.php?title=special:search&search=${encodeURIComponent(
        this.searchInput
      )}`;
    }
  }

  search(): void {
    if (this.searchInput) {
      location.href = `https://librewiki.net/index.php?title=special:search&search=${encodeURIComponent(
        this.searchInput
      )}&fulltext=search`;
    }
  }

  async getMedaiwikiSearch(key: string): Promise<void> {
    try {
      const { data } = await axios.get<AutoCompleteResponse>(
        "https://librewiki.net/api.php",
        {
          params: {
            action: "query",
            list: "search",
            origin: window.origin,
            format: "json",
            srsearch: key,
            srlimit: 10,
          },
        }
      );
      this.autoCompleteItem = [];
      data.query.search.forEach((item) =>
        this.autoCompleteItem.push(item.title)
      );
      return;
    } catch (err) {
      console.log(err);
    }
  }
}
