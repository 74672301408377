

































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { DateTime } from "luxon";

const documentNamespaces = "0|4|10|12|14|1600";
const topicNamespaces = "1|3|5|7|9|11|13|15|2600|1601|1063|3604|3605";
const limit = 10;

interface RecentChangeResponse {
  query: {
    recentchanges: RecentChangeResponseItem[];
  };
}

interface RecentChangeResponseItem {
  timestamp: string;
  type: string;
  title: string;
}

interface RecentChangeItem {
  key: string;
  timeString: string;
  isNew: boolean;
  text: string;
  to: string;
}

@Component
export default class LiveRecent extends Vue {
  error = false;
  pending = false;
  items: RecentChangeItem[] = [];
  mode = "DOCUMENT";

  async fetchLiveRecent(): Promise<void> {
    if (window.innerWidth < 769) return;
    if (this.pending) return;
    this.pending = true;
    try {
      const { data } = await axios.get<RecentChangeResponse>(
        "https://librewiki.net/api.php",
        {
          params: {
            action: "query",
            origin: window.origin,
            list: "recentchanges",
            rcprop: "title|timestamp",
            rcshow: "!bot|!redirect",
            rctype: "edit|new",
            rclimit: limit,
            format: "json",
            rcnamespace:
              this.mode === "DOCUMENT" ? documentNamespaces : topicNamespaces,
            rctoponly: true,
          },
        }
      );
      this.items = data.query.recentchanges.map(
        (item: RecentChangeResponseItem) => {
          let timeString: string;
          const updateTime = DateTime.fromISO(item.timestamp);
          if (updateTime < DateTime.now().minus({ days: 1 })) {
            timeString = updateTime.toRelative() || "";
          } else {
            timeString = updateTime.toFormat("HH:mm:ss");
          }
          const isNew = item.type === "new";
          return {
            key: timeString + item.title,
            timeString,
            isNew,
            text: item.title,
            to: `https://librewiki.net/wiki/${encodeURIComponent(item.title)}`,
          };
        }
      );
    } catch (err) {
      console.log(err);
      this.error = true;
    } finally {
      this.pending = false;
    }
  }
  mounted(): void {
    this.fetchLiveRecent();
    setInterval(this.fetchLiveRecent, 30 * 1000);
  }
}
