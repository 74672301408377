











import { Component, Vue } from "vue-property-decorator";
import store from "@/store";

@Component
export default class ErrorModal extends Vue {
  data = {
    isactive: false,
    errormsg: store.state.modalError,
  };

  mounted(): void {
    this.data.isactive = true;
  }

  modalclose(): void {
    this.data.isactive = false;
    store.commit("setErrormodal", null);
  }
}
