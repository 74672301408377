

























import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import type { Board } from "@/api";
import NavBar from "@/components/NavBar.vue";
import LiveRecent from "@/components/LiveRecent.vue";
import ErrorPage from "@/components/ErrorPage.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import FooterContent from "@/components/FooterContent.vue";
import SiteNotice from "@/components/SiteNotice.vue";
import Tools from "@/components/Tools.vue";

@Component({
  components: {
    NavBar,
    LiveRecent,
    ErrorPage,
    ErrorModal,
    FooterContent,
    SiteNotice,
    Tools,
  },
})
export default class App extends Vue {
  get title(): string {
    return store.state.board.display_name;
  }
  get error(): Error | null {
    return store.state.meta.error;
  }
  get board(): Board {
    return store.state.board;
  }
  get modalerror(): Error | null {
    return store.state.modalError;
  }
}
